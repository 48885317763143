import React, { useEffect, useState } from 'react';
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import BlogOne from "@/components/blog-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";
import axios from 'axios';

const api_url = "https://strapi-app-pecfr.ondigitalocean.app"

export const fetchPosts = async () => {
  try {
    const apiToken = '8a29ffabf5ccb31183dce44f87400df1293f5e29f2afffd8168dfec140930696bcda8f5f3382ca399a889b674e66a06bdc491e1550265480f9846a35ba8b9a0cd1ae53051ca9fc9f9be5dcbf40e2249eed0acf4f3a2bf3acb1e3afb6572764fcf3e1ab9eb2d4f5f98aca18d2cc50cf4ac767ba7582d6bfc6b730a2a944aee8e4';
    const response = await axios.get('https://strapi-app-pecfr.ondigitalocean.app/api/posts-verteres?populate=*', {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Falha ao carregar posts:', error);
    return [];
  }
};



const BlogPage = () => {

  function compareByCreatedAt(a, b) {
    return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
  }

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPostsData = async () => {
      const postsData = await fetchPosts();
      postsData.sort(compareByCreatedAt);
      setPosts(postsData);
    };

    fetchPostsData();
  }, []);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Blog 01 Page">
          <HeaderOne />
          <PageBanner title="Blog 01" name="Blog" />
          <BlogOne posts={posts} url={api_url} />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default BlogPage;
